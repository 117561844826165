import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import { Navigation } from '../../navigation'

const AdminMenuPage = () => {
  return (
    <List component='nav'>
      <ListItem button onClick={() => Navigation.goToAdminBuildings()}>
        <ListItemIcon>
          <HomeOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Majad</ListItemText>
      </ListItem>
    </List>
  )
}

export default AdminMenuPage
