import { Checkbox, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import React from 'react'
import _ from 'lodash'
import * as dateFns from 'date-fns'
import { et } from 'date-fns/locale'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/string'
import { Navigation } from '../../navigation'
import { useMonths } from '../../hooks/readingHooks'
import { useBuildingApartments } from '../../hooks/buildingHooks'
import { useAuth } from '../../contexts/AuthContext'

const ReadingsListPage = () => {
  const { months, isLoading, error } = useMonths()

  if (isLoading) return <CircularProgress />
  if (error) return <div>Error :(</div>
  return (
    <List component='nav'>
      {months.map((month) => (
        <ReadingMonth key={month.id} month={month} />
      ))}
    </List>
  )
}

const ReadingMonth = ({ month }) => {
  const { user } = useAuth()
  const { buildingApartments, isLoading } = useBuildingApartments(user.buildingId)

  if (isLoading) return <></>

  const date = new Date(month.year, month.monthNumber - 1)
  const label = capitalizeTheFirstLetterOfEachWord(dateFns.format(date, 'yyyy MMMM', { locale: et }))

  const onClick = () => Navigation.goToMonthReadings(month.id)

  return (
    <ListItem button divider onClick={onClick}>
      <ListItemText>{label}</ListItemText>
      <ListItemSecondaryAction onClick={onClick}>
        {buildingApartments.map((a) => {
          const hasReading = _.some(month.readings, (r) => r.apartmentId === a.id)
          return <Checkbox key={a.id} size='small' disabled checked={hasReading} onClick={onClick} />
        })}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ReadingsListPage
