import React from 'react'
import { Navigation } from '../../navigation'
import BuildingForm from '../../components/forms/BuildingForm'
import { buildingService } from '../../services/buildingService'
import _ from 'lodash'
import { apartmentService } from '../../services/apartmentService'
import { useBuildings } from '../../hooks/buildingHooks'
import { useApartments } from '../../hooks/apartmentHooks'

const BuildingCreatePage = () => {
  const { remove: invalidateBuildings } = useBuildings()
  const { remove: invalidateApartments } = useApartments()

  const create = async (building) => {
    const createdBuilding = await buildingService.createBuilding({ address: building.address })
    invalidateBuildings()
    _.times(building.apartmentCount, async (i) => {
      await apartmentService.createApartment({ buildingId: createdBuilding.id, name: (i + 1).toString() })
    })
    invalidateApartments()
    Navigation.goBack()
  }

  return <BuildingForm onSubmit={create} />
}

export default BuildingCreatePage
