import { Button, makeStyles } from '@material-ui/core'
import { useFormikContext } from 'formik'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}))

const FormikSubmit = ({ children }) => {
  const { submitForm } = useFormikContext()
  const classes = useStyles()

  return (
    <Button variant='contained' color='primary' size='large' className={classes.button} fullWidth onClick={submitForm}>
      {children}
    </Button>
  )
}

export default FormikSubmit
