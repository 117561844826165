import React from 'react'
import PrivateRoute from '../../components/navigation/PrivateRoute.js'
import PageContainer from '../PageContainer.js'
import AdminMenuPage from './AdminMenuPage.js'
import BuildingCreatePage from './BuildingCreatePage.js'
import BuildingEditPage from './BuildingEditPage.js'
import BuildingsPage from './BuildingsPage'

const AdminRouter = () => {
  return (
    <PageContainer title='Admin'>
      <PrivateRoute exact path='/admin' component={AdminMenuPage} />
      <PrivateRoute exact path='/admin/buildings' component={BuildingsPage} />
      <PrivateRoute exact path='/admin/buildings/new' component={BuildingCreatePage} />
      <PrivateRoute exact path='/admin/buildings/edit/:id' component={BuildingEditPage} />
    </PageContainer>
  )
}

export default AdminRouter
