import { Avatar, Box, Container, Divider, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import OtherLoginForm from '../components/forms/OtherLoginForm'
import { useAuth } from '../contexts/AuthContext'
import { GoogleLogin } from '@react-oauth/google'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  avatar: {
    marginTop: theme.spacing(4),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  form: {
    marginTop: theme.spacing(4),
    alignItems: 'center',
  },
  otherlogin: {
    display: 'flex',
    marginTop: theme.spacing(5),
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const LoginPage = () => {
  const { login } = useAuth()
  const classes = useStyles()
  const showOtherLoginOptions = false

  return (
    <Container maxWidth='sm'>
      <Box className={classes.root}>
        <Typography variant='h2'>Korteriühistu</Typography>
        <Avatar
          src='https://www.theladders.com/wp-content/uploads/friends-happy-190821.jpg'
          className={classes.avatar}
        />
        <Box className={classes.form}>
          <GoogleLogin
            onSuccess={async (res) => await login(res.credential)}
            onError={(err) => console.log(err)}
            useOneTap
            auto_select
          />
        </Box>
      </Box>
      {showOtherLoginOptions &&
        <>
          <Box my={5} />
          <Divider />
          <Box className={classes.otherlogin}>
            <OtherLogin />
          </Box>
        </>
      }
    </Container>
  )
}

const OtherLogin = () => {
  const { loginPassword } = useAuth()

  const loginAdmin = async ({ email, password }) => {
    await loginPassword(email, password)
  }

  return <OtherLoginForm onSubmit={loginAdmin} label={'Login'} />
}

export default LoginPage
