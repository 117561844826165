import history from './history'

const goTo = (path) => {
  if (history.location.pathname === path) {
    return
  }
  history.push({ pathname: path })
  window.scrollTo(0, 0)
}

export const Navigation = {
  goTo,
  goBack: () => history.goBack(),
  goToLogin: () => goTo('/login'),
  goToReadings: () => goTo('/readings'),
  goToUsage: () => goTo('/usage'),
  goToAdmin: () => goTo('/admin'),
  goToAdminBuildings: () => goTo('/admin/buildings'),
  goToBuildingCreate: () => goTo('/admin/buildings/new'),
  goToBuildingEdit: (id) => goTo('/admin/buildings/edit/' + id),
  goToUser: () => goTo('/user'),
  goToMonthReadings: (id) => goTo('/readings/months/' + id),
  goToReadingEdit: (id) => goTo('/readings/edit/' + id),
  goToReadingCreate: (monthId, apartmentId) => goTo(`/readings/new/${monthId}/${apartmentId}`),
  goToChooseApartment: () => goTo('/chooseapartment'),
}
