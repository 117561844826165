import client from '../utils/client'

const login = async (accessToken) => {
  const res = await client.post(`/auth/login`, { accessToken })
  return res.status === 200 && res.data
}

const loginPassword = async (email, password) => {
  const res = await client.post(`/auth/loginpassword`, { email, password })
  return res.status === 200 && res.data
}

const logout = async () => {
  const res = await client.post(`/auth/logout`)
  return res.status === 200
}

const current = async () => {
  const res = await client.get(`/auth/current`)
  return res.status === 200 && res.data
}

export const authService = {
  login,
  loginPassword,
  logout,
  current,
}
