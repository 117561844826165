import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useOnMobile = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  return !matches
}

export const useOnDesktop = () => {
  return !useOnMobile()
}
