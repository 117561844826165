import React, { useEffect } from 'react'
import RestoreIcon from '@material-ui/icons/Restore'
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { Navigation } from '../../navigation'
import _ from 'lodash'
import { useAuth } from '../../contexts/AuthContext'
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useLocation } from 'react-router'

export default function LeftTabs() {
  const location = useLocation()
  const [value, setValue] = React.useState(_.find(tabNavMap, (m) => location.pathname.includes(m.path))?.tabIndex ?? 1)

  useEffect(() => {
    setValue(_.find(tabNavMap, (m) => location.pathname.includes(m.path))?.tabIndex)
  }, [location])

  const { user } = useAuth()
  const disabled = !user?.apartmentId

  if (disabled) return <></>

  const isAdmin = user.isAdmin

  return (
    <Drawer open={true} variant='permanent' style={{ width: '15rem' }}>
      <Toolbar>
        <IconButton size='small' onClick={() => Navigation.goToReadings()}>
          <img src='logo192.png' alt='logo' width={'40em'} />
        </IconButton>
        <Typography variant='h6' style={{ marginLeft: 10 }}>
          Korteriühistu
        </Typography>
      </Toolbar>
      <Divider />
      <List style={{ width: '15rem' }}>
        <ListItem
          button
          onClick={() => {
            Navigation.goToReadings()
          }}
          selected={value === 1}
        >
          <ListItemIcon>
            <RestoreIcon />
          </ListItemIcon>
          <ListItemText primary='Näidud' />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            Navigation.goToUsage()
          }}
          selected={value === 2}
        >
          <ListItemIcon>
            <ShowChartOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Tarbimine' />
        </ListItem>
        {isAdmin && (
          <ListItem
            button
            onClick={() => {
              Navigation.goToAdmin()
            }}
            selected={value === 3}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Admin' />
          </ListItem>
        )}
      </List>
    </Drawer>
  )
}

const tabNavMap = [
  { path: 'readings', tabIndex: 1 },
  { path: 'usage', tabIndex: 2 },
  { path: 'admin', tabIndex: 3 },
]
