import { Redirect, Route } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'

const PrivateRoute = (props) => {
  const { authed } = useAuth()
  const { children, ...rest } = props
  if (!authed) return <Redirect to='/login' />
  return <Route {...rest}>{children}</Route>
}

export default PrivateRoute
