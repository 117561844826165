import React from 'react'
import { CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useBuildings } from '../hooks/buildingHooks'
import { buildingService } from '../services/buildingService'

const BuildingList = ({ onClick }) => {
  const { buildings, isLoading, error, refetch } = useBuildings()

  const onDeleteClicked = async (id) => {
    await buildingService.deleteBuilding(id)
    refetch()
  }

  if (isLoading) return <CircularProgress />
  if (error) return <p>Error :(</p>

  return (
    <List>
      {buildings.map((b) => (
        <ListItem key={b.id} button divider onClick={() => onClick(b.id)}>
          <ListItemText>{b.address}</ListItemText>
          <ListItemSecondaryAction onClick={() => onDeleteClicked(b.id)}>
            <IconButton edge='end'>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

export default BuildingList
