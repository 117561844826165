import { useQuery } from 'react-query'
import { apartmentService } from '../services/apartmentService'

export const useApartments = () => {
  const apartmentQuery = useQuery('apartments', () => apartmentService.getApartments())
  return {
    apartments: apartmentQuery.data ?? undefined,
    ...apartmentQuery,
  }
}
