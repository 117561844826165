import client from '../utils/client'

const getMonthList = async () => {
  const res = await client.get('/months')
  return res.data
}

export const monthService = {
  getMonthList,
}
