import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import client from '../utils/client'
import PageContainer from './PageContainer'
import { saveAs } from 'file-saver'
import { useAuth } from '../contexts/AuthContext'
import { Line } from 'react-chartjs-2'
import { useMonths } from '../hooks/readingHooks'
import _ from 'lodash'
import { useBuildingApartments } from '../hooks/buildingHooks'
import * as dateFns from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
}))

const UsagePage = () => {
  const classes = useStyles()
  const { user } = useAuth()
  const [loading, setLoading] = useState()

  const download = async () => {
    setLoading(true)
    const { data, headers } = await client.get('/usage/report', {
      responseType: 'blob',
      params: { buildingId: user.buildingId },
    })
    const parts = headers['content-disposition'].split("'")
    const fileName = decodeURI(parts[parts.length - 1])
    const file = new File([data], fileName, { type: headers['content-type'] })
    saveAs(file)
    setLoading(false)
  }
  return (
    <PageContainer title='Tarbimine'>
      <Box m={2} className={classes.root}>
        <DownloadButton download={download} loading={loading} />
        <Box my={2} />
        <UsageChart title='Külm vesi' field='coldWater' />
        <Box my={2} />
        <UsageChart title='Soe vesi 1. korrus' field='hotWaterFirst' />
        <Box my={2} />
        <UsageChart title='Soe vesi 2. korrus' field='hotWaterSecond' />
        <Box my={2} />
        <UsageChart title='Küttekulu' field='heating' />
        <Box mb={6} />
      </Box>
    </PageContainer>
  )
}

const DownloadButton = ({ download, loading }) => {
  if (loading) return <CircularProgress />

  return (
    <Button variant='contained' color='primary' size='large' fullWidth onClick={download}>
      Tõmba näitude raport
    </Button>
  )
}

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
}

const colors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
]

const UsageChart = ({ title, field }) => {
  const { months } = useMonths()
  const { user } = useAuth()
  const { buildingApartments: apartments, isLoading } = useBuildingApartments(user.buildingId)
  if (isLoading) return <CircularProgress />

  const sorted = _.sortBy(months, (m) => new Date(m.year, m.monthNumber - 1))
  const dataMonths = _.take(_.takeRight(sorted, 13), 12)
  const data = {
    labels: _.map(dataMonths, (m) => m.year + ' ' + m.monthNumber),
    datasets: [],
  }

  _.forEach(apartments, (a, idx) => {
    data.datasets.push({
      label: a.name,
      data: _.map(dataMonths, (m) => {
        const monthDate = new Date(m.year, m.monthNumber - 1, 1)
        const prevMonthDate = dateFns.sub(monthDate, { months: 1 })
        const previousMonth = _.find(
          months,
          (x) => x.monthNumber === prevMonthDate.getMonth() + 1 && x.year === prevMonthDate.getFullYear()
        )

        const reading = _.find(m.readings, (r) => r.apartmentId === a.id)
        const prev = previousMonth && _.find(previousMonth.readings, (r) => r.apartmentId === a.id)

        return reading && prev && reading[field] && prev[field] && reading[field] - prev[field]
      }),
      backgroundColor: colors[idx],
      borderColor: colors[idx],
    })
  })

  return (
    <>
      <Typography>{title}</Typography>
      <Line data={data} options={options} />
    </>
  )
}

export default UsagePage
