import React from 'react'
import { useRouteMatch } from 'react-router'
import ReadingForm from '../../components/forms/ReadingForm'
import { Navigation } from '../../navigation'
import { useCreateReading, useMonth } from '../../hooks/readingHooks'
import { useBuilding, useBuildingApartments } from '../../hooks/buildingHooks'
import { useAuth } from '../../contexts/AuthContext'
import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import * as dateFns from 'date-fns'
import { et } from 'date-fns/locale'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/string'

const ReadingCreatePage = () => {
  const { params } = useRouteMatch('*readings/new/:monthId/:apartmentId')
  const { user } = useAuth()
  const { building } = useBuilding(user.buildingId)
  const { buildingApartments } = useBuildingApartments(user.buildingId)
  const apartment = _.find(buildingApartments, (a) => a.id === params.apartmentId)
  const { month } = useMonth(params.monthId)
  const title = dateFns.format(new Date(month.year, month.monthNumber - 1), 'yyyy MMMM', { locale: et })

  const createReading = useCreateReading()
  const submit = async (values) => {
    await createReading({
      monthId: params.monthId,
      apartmentId: params.apartmentId,
      coldWater: values.coldWater,
      hotWaterFirst: values.hotWaterFirst,
      hotWaterSecond: values.hotWaterSecond,
      heating: values.heating,
    })
    Navigation.goBack()
  }

  return (
    <Box my={2}>
      <Typography variant='h4' align='center'>
        {capitalizeTheFirstLetterOfEachWord(title)}
      </Typography>
      <Typography variant='h5' align='center'>
        {building.address + ' ' + apartment.name}
      </Typography>
      <ReadingForm onSubmit={submit} />
    </Box>
  )
}

export default ReadingCreatePage
