import { useQuery } from 'react-query'
import { buildingService } from '../services/buildingService'

export const useBuildings = () => {
  const buildingQuery = useQuery('buildings', () => buildingService.getBuildings())
  return {
    buildings: buildingQuery.data ?? undefined,
    ...buildingQuery,
  }
}

export const useBuilding = (id) => {
  const buildingQuery = useQuery(`buildings_${id}`, () => buildingService.getBuilding(id))
  return {
    building: buildingQuery.data ?? undefined,
    ...buildingQuery,
  }
}

export const useBuildingApartments = (id) => {
  const apartmentQuery = useQuery(`building_apartments_${id}`, () => buildingService.getBuildingApartments(id), {
    enabled: !!id,
  })
  return {
    buildingApartments: apartmentQuery.data ?? [],
    ...apartmentQuery,
  }
}
