import _ from 'lodash'
import React from 'react'
import { useRouteMatch } from 'react-router'
import BuildingForm from '../../components/forms/BuildingForm'
import { useApartments } from '../../hooks/apartmentHooks'
import { useBuilding, useBuildingApartments, useBuildings } from '../../hooks/buildingHooks'
import { apartmentService } from '../../services/apartmentService'
import { buildingService } from '../../services/buildingService'

const BuildingEditPage = () => {
  const match = useRouteMatch('*buildings/edit/:id')
  const buildingId = match?.params?.id
  const { building, isLoading, error, remove: invalidateBuilding } = useBuilding(buildingId)
  const { remove: invalidateBuildings } = useBuildings()
  const { remove: invalidateApartments } = useApartments()
  const { buildingApartments, remove: invalidateBuildingApartments } = useBuildingApartments(buildingId)

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error</div>

  const edit = async (building) => {
    buildingService.updateBuilding(buildingId, { address: building.address })
    invalidateBuilding()
    invalidateBuildings()

    if (building.apartmentCount < buildingApartments.length) {
      const excessApartments = _.filter(buildingApartments, (x) => parseInt(x.name) > building.apartmentCount)
      _.forEach(excessApartments, async (x) => {
        await apartmentService.deleteApartment(x.id)
      })
    } else if (building.apartmentCount > buildingApartments.length) {
      const neededApartmentsCount = building.apartmentCount - buildingApartments.length
      _.times(neededApartmentsCount, async (i) => {
        await apartmentService.createApartment({
          buildingId: buildingId,
          name: (buildingApartments.length + i + 1).toString(),
        })
      })
    }
    invalidateApartments()
    invalidateBuildingApartments()
  }

  return (
    <BuildingForm building={{ address: building.address, apartmentCount: buildingApartments.length }} onSubmit={edit} />
  )
}

export default BuildingEditPage
