import { FormControl, InputLabel, Select } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'

const FormikSelect = ({ children, onSelected, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { label } = props

  const handleSelect = (evt) => {
    const val = evt.target.value
    helpers.setValue(val)
    !!onSelected && onSelected(val)
  }

  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel id={field.name + '-label'}>{label}</InputLabel>
      <Select
        labelId={field.name + '-label'}
        id={field.name}
        label={label}
        value={meta.value}
        error={meta.error && meta.touched}
        onChange={handleSelect}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default FormikSelect
