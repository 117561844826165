import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../contexts/AuthContext'
import { authService } from '../services/authService'
import { userService } from '../services/userService'

export const useCurrentUser = () => {
  const userQuery = useQuery('user_current', () => authService.current())
  return {
    user: userQuery.data ?? undefined,
    isLoading: userQuery.isLoading,
    remove: userQuery.remove,
    refetch: userQuery.refetch,
  }
}

export const useMutateCurrentUser = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  return useMutation(async ({ apartmentId }) => await userService.updateUser(user.id, { apartmentId }), {
    onSuccess: (data) => {
      queryClient.setQueryData('user_current', data)
    },
  })
}
