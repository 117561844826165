import { Box } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React from 'react'
import FormikTextInput from '../FormikTextInput'
import FormikSubmit from '../FormikSubmit'
import * as Yup from 'yup'

const waterSchema = Yup.number().required('Täida väli')
const heatingSchema = Yup.number().required('Täida väli')

const ReadingSchema = Yup.object().shape({
  coldWater: waterSchema,
  hotWaterFirst: waterSchema,
  hotWaterSecond: waterSchema,
  heating: heatingSchema,
})

const ReadingForm = ({ reading = null, onSubmit }) => {
  const submit = (values) => {
    onSubmit({
      coldWater: parseFloat(values.coldWater),
      hotWaterFirst: parseFloat(values.hotWaterFirst),
      hotWaterSecond: parseFloat(values.hotWaterSecond),
      heating: parseFloat(values.heating),
    })
  }
  return (
    <Box m={2}>
      <Formik
        initialValues={{
          coldWater: reading?.coldWater,
          hotWaterFirst: reading?.hotWaterFirst,
          hotWaterSecond: reading?.hotWaterSecond,
          heating: reading?.heating,
        }}
        validationSchema={ReadingSchema}
        onSubmit={submit}
      >
        {() => (
          <Form>
            <FormikTextInput name='coldWater' label='Külm vesi' inputMode='decimal' />
            <Box my={2} />
            <FormikTextInput name='hotWaterFirst' label='Soe vesi 1k' inputMode='decimal' />
            <Box my={2} />
            <FormikTextInput name='hotWaterSecond' label='Soe vesi 2k' inputMode='decimal' />
            <Box my={2} />
            <FormikTextInput name='heating' label='Küte' inputMode='numeric' />
            <FormikSubmit>Salvesta</FormikSubmit>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ReadingForm
