import React from 'react'
import { useRouteMatch } from 'react-router'
import _ from 'lodash'
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import * as dateFns from 'date-fns'
import { et } from 'date-fns/locale'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/string'
import FixedActionIcon from '../../components/FixedActionIcon'
import { Navigation } from '../../navigation'
import { useMonth, usePreviousMonth } from '../../hooks/readingHooks'
import { useBuilding, useBuildingApartments } from '../../hooks/buildingHooks'
import { useAuth } from '../../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
  table: {
    '& th, td': {
      padding: theme.spacing(1),
    },
  },
}))

const MonthReadingsPage = () => {
  const match = useRouteMatch('*months/:id')
  const monthId = match?.params?.id

  const { user } = useAuth()
  const { month, isLoading, error } = useMonth(monthId)
  const { previousMonth } = usePreviousMonth(monthId)

  if (isLoading) return <CircularProgress />
  if (error) return <div>Error :(</div>

  const title = dateFns.format(new Date(month.year, month.monthNumber - 1), 'yyyy MMMM', { locale: et })
  const userApartmentReading = _.find(month.readings, (r) => r.apartmentId === user.apartmentId)

  return (
    <Box my={2}>
      <Typography variant='h4' align='center'>
        {capitalizeTheFirstLetterOfEachWord(title)}
      </Typography>
      <Box my={2} />
      <ApartmentTables readings={month.readings} prevReadings={previousMonth?.readings} />
      {userApartmentReading && (
        <FixedActionIcon action='edit' onClick={() => Navigation.goToReadingEdit(userApartmentReading.id)} />
      )}
      {!userApartmentReading && (
        <FixedActionIcon action='add' onClick={() => Navigation.goToReadingCreate(month.id, user.apartmentId)} />
      )}
    </Box>
  )
}

const rowDefinitions = [
  { field: 'coldWater', label: 'Külm vesi', decimalPoints: 2 },
  { field: 'hotWaterFirst', label: 'Soe vesi 1.', decimalPoints: 2 },
  { field: 'hotWaterSecond', label: 'Soe vesi 2.', decimalPoints: 2 },
  { field: 'heating', label: 'Küttekulu', decimalPoints: 0 },
]

const ApartmentTables = ({ readings, prevReadings }) => {
  const { user } = useAuth()
  const { building } = useBuilding(user.buildingId)
  const { buildingApartments, isLoading } = useBuildingApartments(user.buildingId)
  if (isLoading) return <CircularProgress />

  const tables = _.map(buildingApartments, (a) => {
    const prev = prevReadings && _.find(prevReadings, (r) => r.apartmentId === a.id)
    const current = readings && _.find(readings, (r) => r.apartmentId === a.id)

    const table = { name: building.address + ' ' + a.name }

    table.rows = _.map(rowDefinitions, (rowDef) => {
      const row = {
        name: rowDef.label,
        prev: prev && prev[rowDef.field],
        current: current && current[rowDef.field],
        decimalPoints: rowDef.decimalPoints,
      }
      row.usage = row.prev && row.current && row.current - row.prev
      return row
    })

    return table
  })

  return (
    <Box m={1} mb={17}>
      {tables.map((t) => (
        <Box mb={3} key={t.name}>
          <Typography variant='h6'>{t.name}</Typography>
          <ReadingDataTable data={t.rows} />
        </Box>
      ))}
    </Box>
  )
}

const ReadingDataTable = ({ data }) => {
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell width='25%'>Näit</TableCell>
            <TableCell width='20%' align='right'>
              Algnäit
            </TableCell>
            <TableCell width='20%' align='right'>
              Lõppnäit
            </TableCell>
            <TableCell width='20%' align='right'>
              Tarbitud
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((r, i) => {
            return (
              <TableRow key={i}>
                <TableCell width='25%'>{r.name}</TableCell>
                <TableCell width='20%' align='right'>
                  {r.prev}
                </TableCell>
                <TableCell width='20%' align='right'>
                  {r.current}
                </TableCell>
                <TableCell width='20%' align='right'>
                  {r.usage?.toFixed(r.decimalPoints)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

// const ReadingsTable = ({ readings }) => {
//   const { apartments } = useApartments()
//   const classes = useStyles()
//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table}>
//         <TableHead>
//           <TableRow>
//             <TableCell width='4%'>K.</TableCell>
//             <TableCell width='24%' align='right'>
//               Külm
//             </TableCell>
//             <TableCell width='24%' align='right'>
//               Soe 1.
//             </TableCell>
//             <TableCell width='24%' align='right'>
//               Soe 2.
//             </TableCell>
//             <TableCell width='24%' align='right'>
//               Küte
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {readings.map((r) => {
//             const apartment = _.find(apartments, (a) => a.id === r.apartmentId)
//             return (
//               <TableRow key={r.id}>
//                 <TableCell width='4%'>{apartment.name}</TableCell>
//                 <TableCell width='24%' align='right'>
//                   {r.coldWater}
//                 </TableCell>
//                 <TableCell width='24%' align='right'>
//                   {r.hotWaterFirst}
//                 </TableCell>
//                 <TableCell width='24%' align='right'>
//                   {r.hotWaterSecond}
//                 </TableCell>
//                 <TableCell width='24%' align='right'>
//                   {r.heating}
//                 </TableCell>
//               </TableRow>
//             )
//           })}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   )
// }

export default MonthReadingsPage
