import { TextField } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'

const FormikTextInput = (props) => {
  const [field, meta, helpers] = useField(props)

  const onChange = (evt) => {
    const inputValue = evt.target.value
    const transformedValue = inputValue && inputValue.replace(/,/g, '.')
    helpers.setValue(transformedValue)
  }

  return (
    <>
      <TextField
        id={field.name}
        label={props.label}
        inputProps={{
          inputMode: props.inputMode || 'text',
          type: props.type || 'text',
        }}
        value={meta.value}
        error={meta.error && meta.touched}
        helperText={meta.error}
        variant='outlined'
        fullWidth
        onChange={onChange}
        autoComplete='off'
      />
    </>
  )
}

export default FormikTextInput
