import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined'
import React from 'react'
import PageContainer from './PageContainer'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { Navigation } from '../navigation'
import { useAuth } from '../contexts/AuthContext'

const UserPage = () => {
  const { user } = useAuth()
  return (
    <PageContainer title='Kasutaja'>
      <Box display='flex' justifyContent='center' my={4}>
        <Typography variant='h5'>Tere, {user.name}</Typography>
      </Box>
      <Divider />
      <Box>
        <UserMenu />
      </Box>
    </PageContainer>
  )
}

const UserMenu = () => {
  const { logout } = useAuth()
  const onChooseApartment = () => {
    Navigation.goToChooseApartment()
  }

  return (
    <List component='nav'>
      <ListItem button onClick={onChooseApartment}>
        <ListItemIcon>
          <HomeWorkOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Vali korter</ListItemText>
      </ListItem>
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText>Logi välja</ListItemText>
      </ListItem>
    </List>
  )
}

export default UserPage
