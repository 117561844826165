import { Box, MenuItem } from '@material-ui/core'
import { Form, Formik, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import FormikSelect from '../FormikSelect'
import _ from 'lodash'
import FormikSubmit from '../FormikSubmit'

const ChooseApartment = ({ buildings, apartments, user, onBuildingChosen, onApartmentChosen }) => {
  const submit = async (values) => {
    if (!!onApartmentChosen) onApartmentChosen(values.apartmentId)
  }

  return (
    <Box m={2}>
      <Formik
        initialValues={{
          buildingId: user.buildingId ?? (buildings.length > 0 && buildings[0].id) ?? undefined,
          apartmentId: user.apartmentId ?? (apartments.length > 0 && apartments[0].id) ?? undefined,
        }}
        onSubmit={submit}
      >
        <Form>
          <BuildingSelection buildings={buildings} onChosen={onBuildingChosen} />
          <Box my={2} />
          <ApartmentSelection apartments={apartments} user={user} />
          <FormikSubmit>Vali</FormikSubmit>
        </Form>
      </Formik>
    </Box>
  )
}

const BuildingSelection = ({ buildings, onChosen }) => {
  return (
    <FormikSelect name='buildingId' label='Maja' onSelected={onChosen}>
      {buildings.map((b) => (
        <MenuItem key={b.id} value={b.id}>
          {b.address}
        </MenuItem>
      ))}
    </FormikSelect>
  )
}

const ApartmentSelection = ({ apartments, user }) => {
  const { setFieldValue, getFieldMeta } = useFormikContext()
  const { value } = getFieldMeta('apartmentId')

  useEffect(() => {
    if (_.some(apartments, (a) => a.id === user.apartmentId)) {
      setFieldValue('apartmentId', user.apartmentId)
    } else if (apartments.length) {
      setFieldValue('apartmentId', apartments[0].id)
    }
  }, [setFieldValue, apartments, user.apartmentId])

  if (apartments.length <= 1) {
    return <></>
  }

  if (!_.some(apartments, (a) => a.id === value)) return <></>

  return (
    <FormikSelect name='apartmentId' label='Korter'>
      {apartments.map((a) => (
        <MenuItem key={a.id} value={a.id}>
          {a.name}
        </MenuItem>
      ))}
    </FormikSelect>
  )
}

export default ChooseApartment
