import { Box } from '@material-ui/core'
import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikTextInput from '../FormikTextInput'
import FormikSubmit from '../FormikSubmit'

const BuildingSchema = Yup.object().shape({
  address: Yup.string().min(2, 'Aadress on liiga lühike').max(50, 'Aadress on liiga pikk').required('Täida väli'),
  apartmentCount: Yup.number()
    .typeError('Peab olema number')
    .min(1, 'Peab olema vähemalt 1 korter')
    .required('Täida väli!'),
})

const BuildingForm = ({ building, onSubmit }) => {
  const submit = (values) => {
    const { apartmentCount, ...rest } = values
    onSubmit({
      apartmentCount: parseInt(apartmentCount),
      ...rest,
    })
  }

  return (
    <Box m={2}>
      <Formik
        initialValues={{
          address: building?.address,
          apartmentCount: building?.apartmentCount,
        }}
        validationSchema={BuildingSchema}
        onSubmit={submit}
      >
        {() => (
          <Form>
            <FormikTextInput name='address' label='Aadress' />
            <Box my={2} />
            <FormikTextInput name='apartmentCount' label='Kortereid' inputMode='numeric' />
            <FormikSubmit>Save</FormikSubmit>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default BuildingForm
