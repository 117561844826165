import { useLocation } from 'react-router'
import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import BackIcon from '@material-ui/icons/ArrowBack'
import React from 'react'
import { Navigation } from '../navigation'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}))

const PageContainer = ({ title, children }) => {
  const classes = useStyles()
  const loc = useLocation()
  const isUserPage = loc.pathname.includes('/user')
  const allowBack = loc.pathname.split('/').length > 2 || isUserPage

  return (
    <>
      <AppBar position='sticky'>
        <Toolbar>
          {allowBack && <BackButton />}
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
          {!isUserPage && <AccountHero />}
        </Toolbar>
      </AppBar>
      {children}
    </>
  )
}

const BackButton = () => {
  const classes = useStyles()
  return (
    <IconButton edge='start' className={classes.backButton} color='inherit' onClick={() => Navigation.goBack()}>
      <BackIcon />
    </IconButton>
  )
}

const AccountHero = () => {
  return (
    <IconButton color='inherit' onClick={() => Navigation.goToUser()}>
      <AccountCircle />
    </IconButton>
  )
}

export default PageContainer
