import { Fab, makeStyles, useTheme, Zoom } from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(9),
    right: theme.spacing(4),
    color: theme.palette.background.default,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const FixedActionIcon = ({ onClick, action = 'add' }) => {
  const theme = useTheme()
  const classes = useStyles()
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }
  return (
    <Zoom
      in={true}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.exit}ms`,
      }}
      unmountOnExit
    >
      <Fab className={classes.fab} onClick={onClick}>
        {action === 'add' && <AddIcon />}
        {action === 'edit' && <EditIcon />}
      </Fab>
    </Zoom>
  )
}

export default FixedActionIcon
