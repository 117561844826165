import React, { useEffect, useState } from 'react'
import theme from './theme'
import { Box, CircularProgress, CssBaseline, ThemeProvider } from '@material-ui/core'
import BottomTabs from './components/navigation/BottomTabs'
import AppRouter from './AppRouter'
import UpdateSnackbar from './components/UpdateSnackbar'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './utils/queryClient'
import { Suspense } from 'react'
import AuthProvider from './contexts/AuthContext'
import { useCurrentUser } from './hooks/userHooks'
import { useOnMobile } from './hooks/theming'
import LeftTabs from './components/navigation/LeftTabs'
import { Router } from 'react-router'
import history from './history'
import { GoogleOAuthProvider } from '@react-oauth/google'

function App() {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <GoogleOAuthProvider clientId='717029906407-t0maedhjsnp03sd99jguir9jkrr5ug51.apps.googleusercontent.com'>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
              <Content />
            </AuthProvider>
            <UpdateSnackbar />
          </ThemeProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Suspense>
  )
}

const Content = () => {
  const { isLoading } = useCurrentUser()
  const isMobile = useOnMobile()

  if (isLoading) {
    return <CenteredLoader />
  }
  return <Router history={history}>{isMobile ? <Mobile /> : <Desktop />}</Router>
}

const Mobile = () => (
  <>
    <AppRouter />
    <BottomTabs />
  </>
)

const Desktop = () => (
  <Box maxWidth={'65rem'} margin={'auto'} display={'flex'}>
    <LeftTabs />
    <Box flexDirection={'column'} flexGrow={1} component='main'>
      <AppRouter />
    </Box>
  </Box>
)

const CenteredLoader = () => (
  <Box style={{ position: 'absolute', top: '45%', left: '45%' }}>
    <CircularProgress />
  </Box>
)

export default App
