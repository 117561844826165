import React, { useEffect, useState } from 'react'
import PageContainer from './PageContainer'
import ChooseApartmentForm from '../components/forms/ChooseApartmentForm'
import { Navigation } from '../navigation'
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { useMutateCurrentUser } from '../hooks/userHooks'
import { useBuildingApartments, useBuildings } from '../hooks/buildingHooks'
import { useAuth } from '../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
}))

const ChooseApartmentPage = () => {
  const classes = useStyles()
  const { user } = useAuth()
  const { buildings, isLoading: buildingsLoading } = useBuildings()

  const [chosenBuilding, setChosenBuilding] = useState()
  const { buildingApartments, isLoading: apartmentsLoading } = useBuildingApartments(chosenBuilding)

  const currentUserMutation = useMutateCurrentUser()

  const onChosen = async (apartmentId) => {
    await currentUserMutation.mutateAsync({ apartmentId })
    Navigation.goToReadings()
  }

  const onBuildingChosen = (buildingId) => {
    setChosenBuilding(buildingId)
  }
  useEffect(() => {
    if (user.buildingId) {
      setChosenBuilding(user.buildingId)
    } else if (buildings.length > 0) {
      setChosenBuilding(buildings[0].id)
    }
  }, [user.buildingId, buildings])

  const ready = !buildingsLoading && !apartmentsLoading
  return (
    <PageContainer title='Vali korter'>
      <Box className={classes.title}>
        <Typography variant='h4'>Vali korter</Typography>
      </Box>
      {!ready && (
        <Box m={2}>
          <CircularProgress />
        </Box>
      )}
      {ready && (
        <ChooseApartmentForm
          user={user}
          buildings={buildings}
          apartments={buildingApartments}
          onBuildingChosen={onBuildingChosen}
          onApartmentChosen={onChosen}
        />
      )}
    </PageContainer>
  )
}

export default ChooseApartmentPage
