import { red } from '@material-ui/core/colors'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#34a0a4',
    },
    secondary: {
      main: '#db9487',
    },
    text: {
      primary: '#34a0a4',
      secondary: '#a1c9c9',
    },
    action: {
      active: '#34a0a4',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f6f7f1',
    },
  },
})

// #f6f7f1 - Background (whiteish)
// #34a0a4 - Text (primary blueish)
// #a1c9c9 - faded (blueish)
// #db9487 - elements (pinkish)

export default theme
