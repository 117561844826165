import { Box, Typography } from '@material-ui/core'
import React from 'react'
import BuildingList from '../../components/BuildingList'
import FixedActionIcon from '../../components/FixedActionIcon'

import { Navigation } from '../../navigation'

const BuildingsPage = () => {
  return (
    <>
      <Box m={2}>
        <Typography variant='h6'>Majad</Typography>
      </Box>
      <BuildingList onClick={(id) => Navigation.goToBuildingEdit(id)} />
      <FixedActionIcon onClick={() => Navigation.goToBuildingCreate()} />
    </>
  )
}

export default BuildingsPage
