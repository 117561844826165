import React, { useEffect } from 'react'
import PageContainer from '../PageContainer.js'
import MonthReadingsPage from './MonthReadingsPage.js'
import ReadingsListPage from './ReadingsListPage.js'
import ReadingEditPage from './ReadingEditPage'
import ReadingCreatePage from './ReadingCreatePage'
import PrivateRoute from '../../components/navigation/PrivateRoute.js'
import { useCurrentUser } from '../../hooks/userHooks.js'
import { Navigation } from '../../navigation.js'

const ReadingsRouter = () => {
  const { user, isLoading } = useCurrentUser()

  useEffect(() => {
    if (isLoading) return
    if (!user.apartmentId) {
      Navigation.goToChooseApartment()
    }
  }, [user.apartmentId, isLoading])

  return (
    <PageContainer title='Näidud'>
      <PrivateRoute exact path='/readings' component={ReadingsListPage} />
      <PrivateRoute exact path='/readings/months/:id' component={MonthReadingsPage} />
      <PrivateRoute exact path='/readings/edit/:id' component={ReadingEditPage} />
      <PrivateRoute exact path='/readings/new/:monthId/:apartmentId' component={ReadingCreatePage} />
    </PageContainer>
  )
}

export default ReadingsRouter
