import client from '../utils/client'

const getBuildings = async () => {
  const res = await client.get('/buildings')
  return res.data
}

const createBuilding = async (building) => {
  const res = await client.post('/buildings', building)
  return res.data
}

const getBuilding = async (id) => {
  const res = await client.get(`/buildings/${id}`)
  return res.data
}

const updateBuilding = async (id, building) => {
  const res = await client.put(`/buildings/${id}`, building)
  return res.data
}

const deleteBuilding = async (id) => {
  const res = await client.delete(`/buildings/${id}`)
  return res.data
}

const getBuildingApartments = async (id) => {
  const res = await client.get(`/buildings/${id}/apartments`)
  return res.data
}

export const buildingService = {
  getBuildings,
  createBuilding,
  getBuilding,
  updateBuilding,
  deleteBuilding,
  getBuildingApartments,
}
