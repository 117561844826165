import client from '../utils/client'

const getApartments = async () => {
  const res = await client.get('/apartments')
  return res.data
}

const createApartment = async (apartment) => {
  const res = await client.post('/apartments', apartment)
  return res.data
}

const getApartment = async (id) => {
  const res = await client.get(`/apartments/${id}`)
  return res.data
}

const updateApartment = async (id, apartment) => {
  const res = await client.put((`/apartments/${id}`, apartment))
  return res.data
}

const deleteApartment = async (id) => {
  const res = await client.delete(`/apartments/${id}`)
  return res.data
}

export const apartmentService = {
  getApartments,
  createApartment,
  getApartment,
  updateApartment,
  deleteApartment,
}
