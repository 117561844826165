/* eslint-disable no-undef */
import { createContext, useState, useContext, useEffect } from 'react'
import { useCurrentUser } from '../hooks/userHooks'
import { authService } from '../services/authService'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [authed, setAuthed] = useState(false)
  const { user, refetch } = useCurrentUser()

  useEffect(() => {
    setAuthed(!!user)
  }, [user, setAuthed])

  const login = async (accessToken) => {
    await authService.login(accessToken)
    refetch()
  }
  const loginPassword = async (email, password) => {
    await authService.loginPassword(email, password)
    refetch()
  }
  const logout = async () => {
    await authService.logout()
    refetch()
  }
  return <AuthContext.Provider value={{ authed, user, login, loginPassword, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const { authed, user, login, loginPassword, logout } = useContext(AuthContext)
  return {
    authed,
    login,
    loginPassword,
    logout,
    user,
  }
}

export default AuthProvider
