import client from '../utils/client'

const createReading = async (reading) => {
  const res = await client.post('/readings', reading)
  return res.data
}

const updateReading = async (id, reading) => {
  const res = await client.put(`/readings/${id}`, reading)
  return res.data
}

const deleteReading = async (id) => {
  const res = await client.delete(`/readings/${id}`)
  return res.data
}

export const readingService = {
  createReading,
  updateReading,
  deleteReading,
}
