import _ from 'lodash'
import { useQuery, useQueryClient } from 'react-query'
import { monthService } from '../services/monthService'
import { readingService } from '../services/readingService'
import * as dateFns from 'date-fns'

export const useMonths = () => {
  const monthsQuery = useQuery('months', () => monthService.getMonthList())
  return {
    months: monthsQuery.data ?? [],
    ...monthsQuery,
  }
}

export const useMonth = (id) => {
  const { months, ...rest } = useMonths()
  if (!months) return rest
  const month = _.find(months, (x) => x.id === id)
  return { month, ...rest }
}

export const usePreviousMonth = (id) => {
  const { months, ...rest } = useMonths()
  if (!months) return rest
  const month = _.find(months, (x) => x.id === id)
  const monthDate = new Date(month.year, month.monthNumber - 1, 1)
  const prevMonthDate = dateFns.sub(monthDate, { months: 1 })
  const previousMonth = _.find(
    months,
    (x) => x.monthNumber === prevMonthDate.getMonth() + 1 && x.year === prevMonthDate.getFullYear()
  )
  return { previousMonth, ...rest }
}

export const useReading = (id) => {
  const { months, ...rest } = useMonths()
  if (!months) return rest
  const month = _.find(months, (x) => _.some(x.readings, (r) => r.id === id))
  const reading = _.find(month.readings, (r) => r.id === id)
  return { reading, ...rest }
}

export const useCreateReading = () => {
  const queryClient = useQueryClient()

  const createReading = async (reading) => {
    const createdReading = await readingService.createReading(reading)
    queryClient.refetchQueries({ queryKey: 'months' })
    return createdReading
  }

  return createReading
}

export const useUpdateReading = (id) => {
  const queryClient = useQueryClient()

  const updateReading = async (reading) => {
    const updatedReading = await readingService.updateReading(id, reading)
    queryClient.refetchQueries({ queryKey: 'months' })
    return updatedReading
  }

  return updateReading
}
