import client from '../utils/client'

const getCurrent = async () => {
  const res = await client.get('/users/current')
  return res.data
}

const updateUser = async (id, user) => {
  const res = await client.put(`/users/${id}`, user)
  return res.data
}

export const userService = {
  getCurrent,
  updateUser,
}
