import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { VersionContext } from './utils/versionContext'

const Wrapper = () => {
  const [offerUpdate, setOfferUpdate] = useState(false)
  const [sw, setSw] = useState(null)

  useEffect(() => {
    const handler = (evt) => {
      setSw(evt.detail)
      setOfferUpdate(true)
    }
    window.addEventListener('update-available', handler)
    return () => window.removeEventListener('update-available', handler)
  }, [])

  return (
    <VersionContext.Provider value={{ offerUpdate, setOfferUpdate, sw }}>
      <App />
    </VersionContext.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Wrapper />
  </React.StrictMode>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const updateEvent = new CustomEvent('update-available', { detail: registration })
    window.dispatchEvent(updateEvent)
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
