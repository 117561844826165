import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import RestoreIcon from '@material-ui/icons/Restore'
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { Navigation } from '../../navigation'
import _ from 'lodash'
import { useAuth } from '../../contexts/AuthContext'
import { useLocation } from 'react-router'

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
})

export default function BottomTabs() {
  const location = useLocation()
  const [value, setValue] = React.useState(_.find(tabNavMap, (m) => location.pathname.includes(m.path))?.tabIndex ?? 1)

  useEffect(() => {
    setValue(_.find(tabNavMap, (m) => location.pathname.includes(m.path))?.tabIndex)
  }, [location])

  const classes = useStyles()
  const { user } = useAuth()
  const disabled = !user?.apartmentId

  if (disabled) return <></>

  const isAdmin = user.isAdmin

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label='Näidud' icon={<RestoreIcon />} onClick={() => Navigation.goToReadings()} />
      <BottomNavigationAction
        label='Tarbimine'
        icon={<ShowChartOutlinedIcon />}
        onClick={() => Navigation.goToUsage()}
      />
      {isAdmin && (
        <BottomNavigationAction label='Admin' icon={<SettingsOutlinedIcon />} onClick={() => Navigation.goToAdmin()} />
      )}
    </BottomNavigation>
  )
}

const tabNavMap = [
  { path: 'readings', tabIndex: 0 },
  { path: 'usage', tabIndex: 1 },
  { path: 'admin', tabIndex: 2 },
]
