import React from 'react'
import ReadingForm from '../../components/forms/ReadingForm'
import { Navigation } from '../../navigation'
import { useRouteMatch } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { useMonth, useReading, useUpdateReading } from '../../hooks/readingHooks'
import { useAuth } from '../../contexts/AuthContext'
import { useBuilding, useBuildingApartments } from '../../hooks/buildingHooks'
import _ from 'lodash'
import * as dateFns from 'date-fns'
import { et } from 'date-fns/locale'
import { Box, Typography } from '@material-ui/core'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/string'

const ReadingEditPage = () => {
  const { params } = useRouteMatch('*readings/edit/:readingId')
  const { reading, isLoading } = useReading(params.readingId)

  const { user } = useAuth()
  const { building } = useBuilding(user.buildingId)
  const { buildingApartments } = useBuildingApartments(user.buildingId)
  const apartment = _.find(buildingApartments, (a) => a.id === reading.apartmentId)
  const { month } = useMonth(reading.monthId)
  const title = dateFns.format(new Date(month.year, month.monthNumber - 1), 'yyyy MMMM', { locale: et })

  const updateReading = useUpdateReading(reading.id)
  const submit = async (values) => {
    await updateReading({
      readingId: params.readingId,
      apartmentId: params.apartmentId,
      coldWater: values.coldWater,
      hotWaterFirst: values.hotWaterFirst,
      hotWaterSecond: values.hotWaterSecond,
      heating: values.heating,
    })
    Navigation.goBack()
  }

  if (isLoading) {
    return <CircularProgress />
  }
  if (!reading) {
    return <></>
  }

  return (
    <Box my={2}>
      <Typography variant='h4' align='center'>
        {capitalizeTheFirstLetterOfEachWord(title)}
      </Typography>
      <Typography variant='h5' align='center'>
        {building.address + ' ' + apartment.name}
      </Typography>
      <ReadingForm reading={reading} onSubmit={submit} />
    </Box>
  )
}

export default ReadingEditPage
