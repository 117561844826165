import { Form, Formik } from 'formik'
import FormikTextInput from '../FormikTextInput'
import FormikSubmit from '../FormikSubmit'
import { Box } from '@material-ui/core'
import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('E-maili formaat on vale').required('E-mail peab olema täidetud'),
  password: Yup.string().required('Parool peab olema täidetud'),
})

const OtherLoginForm = ({ onSubmit, label }) => {
  const submit = (values) => {
    onSubmit({
      email: values.email,
      password: values.password,
    })
  }
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={LoginSchema}
      onSubmit={submit}
    >
      <Form>
        <FormikTextInput name='email' label='E-mail' />
        <Box my={1} />
        <FormikTextInput name='password' label='Parool' type='password' />
        <FormikSubmit>{label}</FormikSubmit>
      </Form>
    </Formik>
  )
}

export default OtherLoginForm
