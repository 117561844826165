import React from 'react'
import { Redirect, Route } from 'react-router'
import UsagePage from './pages/UsagePage'
import UserPage from './pages/UserPage.js'
import AdminRouter from './pages/admin/AdminRouter.js'
import ReadingsRouter from './pages/readings/ReadingsRouter.js'
import ChooseApartmentPage from './pages/ChooseApartmentPage.js'
import LoginPage from './pages/LoginPage.js'
import PrivateRoute from './components/navigation/PrivateRoute.js'

const AppRouter = () => {
  return (
    <>
      <Route path='/login' component={LoginPage} />
      <PrivateRoute path='/'>
        <Redirect to='/readings' />
      </PrivateRoute>
      <PrivateRoute path='/readings' component={ReadingsRouter} />
      <PrivateRoute path='/usage' component={UsagePage} />
      <PrivateRoute path='/admin' component={AdminRouter} />
      <PrivateRoute path='/user' component={UserPage} />
      <PrivateRoute path='/chooseapartment' component={ChooseApartmentPage} />
    </>
  )
}

export default AppRouter
