import { Button, IconButton, Snackbar } from '@material-ui/core'
import React, { useContext } from 'react'
import { VersionContext } from '../utils/versionContext'
import CloseIcon from '@material-ui/icons/Close'

const UpdateSnackbar = () => {
  const context = useContext(VersionContext)

  const handleClose = () => context.setOfferUpdate(false)

  const handleUpdate = () => {
    if (!context.sw?.waiting) return
    context.sw.waiting.addEventListener('statechange', (evt) => {
      if (evt.target.state === 'activated') {
        window.location.reload()
      }
    })
    context.sw.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={context.offerUpdate}
      onClose={handleClose}
      message='Uus versioon saadaval'
      action={
        <>
          <Button color='primary' size='small' onClick={handleUpdate}>
            Värskenda
          </Button>
          <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      }
    />
  )
}

export default UpdateSnackbar
